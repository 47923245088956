import React from "react"
import Layout from "../components/layout"

const Links = () => {
  return (
    <Layout>
      <div className="post-body">
        <p>
          Muchas de las ideas que aquí se intentan divulgar tienen su origen y
          desarrollo en diversos teóricos contemporáneos. Su trabajo vitaliza
          tradiciones que apuntan a la emancipación. Desde aquí vaya nuestra
          gratitud, nuestro aliento y nuestra deuda. Aunque el tono —ni muchos
          de los participantes— de esta página no es académico, se citará, sin
          abrumar, a estas fuentes. Muchos de esos pensadores participan en
          proyectos que consideramos muy valiosos:
        </p>
        <ul className="projects">
          <li>
            <strong>Constelaciones</strong>. Revista de teoría crítica.&nbsp;
            <a href="http://constelaciones-rtc.net/" rel="noreferrer">
              Visitar
            </a>
          </li>

          <li>
            <strong>Precariedad laboral, cuerpo y vida dañada</strong>. Una
            investigación de filosofía social.&nbsp;
            <a href="https://www.ucm.es/precaritylab" rel="noreferrer">
              Visitar
            </a>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default Links

export const Head = () => {
  return <title>Enlaces</title>
}
