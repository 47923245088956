import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./global.css"
import "./normalize.css"
import { container, content } from "./layout.module.css"
import "@fontsource/montserrat"
import "@fontsource/oswald"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={container}>
      <div className={content}>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        {children}
      </div>
      <hr />
      <footer>{new Date().getFullYear()}</footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
