import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import {
  header,
  fdsLogoWrapper,
  fdsLogoText,
  fdsLogo,
  navList,
  navItem,
} from "./header.module.css"

const Header = ({ siteTitle }) => (
  <header className={header}>
    <div className={fdsLogoWrapper}>
      <div className={fdsLogoText}>
        <Link className={fdsLogo} to="/">
          <h1>Fuera de sí</h1>
        </Link>
      </div>
      <p>CRÍTICA&nbsp;&nbsp;SOCIAL</p>
    </div>
    <nav>
      <ul className={navList}>
        <li className={navItem}>
          <Link to="/" activeClassName="active">
            Artículos
          </Link>
        </li>
        <li className={navItem}>
          <Link to="/presentacion" activeClassName="active">
            Presentación
          </Link>
        </li>
        <li className={navItem}>
          <Link to="/links" activeClassName="active">
            Enlaces
          </Link>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
